import React, { useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next"

function MainSlider() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, _) => {
        setIndex(selectedIndex);
    };

    const {t} = useTranslation()

    const buttonText = t("podr")

    // const imageUrl = useWindowWidth() >= 650 ? desktopImage1 : mobileImage1;
    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className="rev_slider">
            <Carousel.Item>
                <img id={'slide-image-5'}
                    className="d-block w-100 image-size"
                    src="/images/slider/5.webp"
                    style={{objectFit:"cover"}}
                    alt="НАЙДЕМ ИНДИВИДУАЛЬНЫЙ ПОДХОД К КАЖДОМУ КЛИЕНТУ"
                />

                <Carousel.Caption className='carousel_text' >
                    <h3 className="slider-text-1">{t("Точно и в срок")}</h3>
                    <h5 style={{textAlign: "left"}}>{t("tamojnya")}</h5>
                    <Button style={{marginTop: "20px", alignItems: 'end'}} className={'slide-button'} onClick={() => window.location.replace("#map-section")}>{buttonText}</Button>
                </Carousel.Caption>

            </Carousel.Item>
            <Carousel.Item>
                <img id={'slide-image-1'}
                    className="d-block w-100 image-size"
                    src="/images/slider/1.webp"
                    style={{objectFit:"cover", whiteSpace:"pre"}}
                    alt='Индивидуальный подход к каждому клиенту'
                />

                <Carousel.Caption className='carousel_text' >
                    <h3 className="slider-text-1">{t("nujno")}</h3>
                    
                    <h5 style={{textAlign: "left"}}>{t("individual")}</h5>
                    <Button style={{marginTop: "20px"}} className={'slide-button'} onClick={() => window.location.replace("#help")}>{buttonText}</Button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img id={'slide-image-2'}
                    className="d-block w-100 image-size"
                    src="/images/slider/2.webp"
                    style={{objectFit:"cover"}}
                    alt="ПОИСК ИНДИВИДУАЛЬНОГО ТЕХНОЛОГИЧЕСКОГО РЕШЕНИЯ"
                />
                <Carousel.Caption className='carousel_text' >
                    <h3 className="slider-text-1 slider_txt_3" >{t("malo")}</h3>
                    <h5 style={{textAlign: "left"}}>{t("kompl")}</h5>
                    <Button style={{marginTop: "20px"}} className={'slide-button'} onClick={() => window.location.replace("#otstrochka")}>{buttonText}</Button>
                 </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
               <img id={'slide-image-3'}
                    className="d-block-modal w-100 image-size"
                    src="/images/slider/3.webp"
                    style={{objectFit:"cover"}}
                    alt="?"
                />
                <Carousel.Caption className='carousel_text' >
                    <h3 className="slider-text-1">{t("twenty")}</h3>
                    <h5 className="slider-text-2" >{t("pro")}</h5>
                    <Button style={{marginTop: "20px"}} className={'slide-button'} onClick={() => window.location.replace("#about")}>{buttonText}</Button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img id={'slide-image-4'}
                    className="d-block w-100 image-size"
                    src="/images/slider/4.webp"
                    style={{objectFit:"cover"}}
                    alt="ДОСТАВКА ПО ВСЕЙ РОССИИ"
                />

                <Carousel.Caption className='carousel_text'>
                    <h3 className="slider-text-1" >{t("vidim")}</h3>
                    <h5 style={{textAlign: "left"}}>{t("time")}</h5>
                    {/*<Button className={'slide-button'} >Подробнее</Button>*/}
                    <Button style={{marginTop: "20px"}} className={'slide-button'} onClick={() => window.location.replace("#about")}>{buttonText}</Button>
                </Carousel.Caption>
            </Carousel.Item>
            
            {/*<Carousel.Item>*/}
            {/*    <img*/}
            {/*        className="d-block w-100"*/}
            {/*   НАЙДЕМ ИНДИВИДУАЛЬНЫЙ ПОДХОД К КАЖДОМУ КЛИЕНТУ     src="/images/slider/slide3.webp"*/}
            {/*        style={{objectFit:"cover"}}*/}
            {/*        height="800"*/}
            {/*        width="800"*/}
            {/*        alt="РАБОТАЕМ С ПЕРЕДОВЫМИ ЗАВОДАМИ ПРОИЗВОДИТЕЛЯМИ"*/}
            {/*    />*/}

            {/*    <Carousel.Caption style={{top:'35%',bottom:'0'}}>*/}
            {/*        <h3 id="slider-text">РАБОТАЕМ С ПЕРЕДОВЫМИ ЗАВОДАМИ ПРОИЗВОДИТЕЛЯМИ</h3>*/}
            {/*    </Carousel.Caption>*/}
            {/*</Carousel.Item>*/}
        </Carousel>
    );
}

export default MainSlider;