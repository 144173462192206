import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import { useTranslation } from 'react-i18next';

function ContactsPage({opened, setOpened }) {
  useEffect(() => {
    document.title = "Полимеркомплект - Контакты";
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");

  // const onSubmit = (e) => {
  //     e.preventDefault();
  //     send(
  //         'service_c7pclcq',
  //         'template_crz0u18',
  //         toSend,
  //         'Kd5hTZnsMrSH5nMAX'
  //     )
  //         .then((response) => {
  //             Swal.fire('Совсем скоро мы с Вами свяжемся', '', 'success');
  //             e.target.reset();
  //         })
  //         .catch((err) => {
  //             Swal.fire('Ошибка при отправке, попробуйте похже', '', 'error');
  //         });
  // };
  function sendEmail() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("to", "noreply@polymercomplect.com");
    urlencoded.append("subject", "Завка с Наши Контакты");
    urlencoded.append(
      "text",
      "Имя: " +
        name +
        ";\n" +
        "Почта: " +
        email +
        ";\n" +
        "Компания: " +
        company +
        ";\n" +
        "Город: " +
        city +
        ";\n" +
        "Телефон номер: " +
        phone
    );
    urlencoded.append(
      "html",
      'Имя: ' +
        name +
        ';\n Почта: '+ email +
        ';\n Компания: '+ company +
        ';\n Город: '+ city +
        ';\n Телефон номер: '+ phone
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.polymercomplect.com/api/sendEmail", requestOptions)
      .then((response) => {
        Swal.fire(
          "Совсем скоро мы с Вами свяжемся",
          "Благодарим за обращение",
          "success"
        );
        setName("");
        setEmail("");
        setCompany("");
        setCity("");
        setPhone("");
        console.log("sent");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Ошибка при отправке, попробуйте позже", "", "error");
      });
  }
  // const handleChange = (e) => {
  //     setToSend({ ...toSend, [e.target.name]: e.target.value });
  // };

  const {t} = useTranslation()

  return (
    <Container id="section-contacts">
      <div>
        
        <div className="contacts_header_text">
            <img  src={"/images/square.png"} alt="left" />
          <h1
            className="custom-bold-40 contacts_head_text"
            
          >
            {t("contact")}
          </h1>
          <div className={`square-image-contacts contacts_square ${opened ? "menu_opened" : ""}`}>
          </div>
        </div>
      </div>
      <Row className="row-contacts">
        <Col lg="6" className="contact-right-left">
          <br />
          <br />
          <p>
            <b>{t("yurid")}</b> {t("add")}
          </p>
          <p>
            <b>{t("telefon")}: </b>
            <a href={"tel:+7 (812) 944-54-26"} target={"_blank"} rel="noreferrer">
            +7 (812) 944-54-26
            </a>
          </p>
          <p>
            <b>{t("poc")} </b>
            <a> polymercomplect@gmail.com</a>
          </p>
          <p>
            <b>{t("workTime")}</b> {t("workTimeTitle")}
          </p>
          <br />
          <p>
            <b>{t("rekzi")}</b> {t("kompa")}  <br />
            {t("sber")}
            <br />
            {t("account")}
            <br />
          </p>
        </Col>
        <Col lg="6" className="contact-right-box">
          <div id="section-contacts-email">
            <div id="section-contacts-text">
              {t("s1")}
              <br /> {t("s2")}
              <br /> {t("s3")}
              <br /> {t("s4")}
            </div>
            <Form id="section-contacts-form">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  name="name"
                  required={true}
                  placeholder={t("name")}
                  value={name}
                  className={"mobileBox custom-input"}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="email"
                  email="email"
                  required={true}
                  placeholder={t("email")}
                  value={email}
                  className={"mobileBox custom-input"}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  company="company"
                  required={true}
                  placeholder={t("yourComp")}
                  className={"mobileBox custom-input"}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                    type="text"
                    city="city"
                    required={true}
                  placeholder={t("yourCity")}
                  className={"mobileBox custom-input"}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                    type="phone"
                    phone="phone"
                    required={true}
                    value={phone}
                  placeholder={t("telefon")}
                  className={"mobileBox custom-input"}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              {/*<Button className="button-contacts" onClick={()=>{sendEmail()}}>*/}
              <Button className="button-contacts" onClick={() => {
                sendEmail();
              }}>{t("zayaw")}</Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default ContactsPage;
