import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

function OtstrochksSection() {
  const [phone, setPhone] = useState("");
  const handleChange = (e) => {
    setPhone(e.target.value);
  };
  function sendEmail() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("to", "noreply@polymercomplect.com");
    urlencoded.append("subject", "Получить консультацию");
    urlencoded.append("text", "Телефон номер: " + phone);
    urlencoded.append("html", "Телефон номер: " + phone);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://api.polymercomplect.com/api/sendEmail", requestOptions)
      .then((response) => {
        Swal.fire("Совсем скоро мы с Вами свяжемся", "sddsfsdfsd", "success");
        // e.target.reset();
        console.log("sent");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Ошибка при отправке, попробуйте позже", "", "error");
      });
  }
//comment

  const {t} = useTranslation()

  return (
    <div className="section5" id="otstrochka">
      <Container>
        <div className="custom-colored-text">
          <div>
            <h1 className="custom-bold-40" style={{ paddingLeft: 60 }}>
              {t("Отсрочка платежа")}
            </h1>
            <div className="square-image-sec1">
              <img src={"/images/square.png"} alt="left" />
            </div>
            <div className="ostrochka_text" style={{fontSize: '16px'}}>
              <p>
                {t("otsText")}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="home_page_images ostrochka_div">
        <img
          className="custom-section1-img ostrochka_img"
          src={"/images/new5.webp"}
          alt="call"
        />
      </div>
    </div>
  );
}

export default OtstrochksSection;
