import React from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from 'react-i18next';
// rgb(80, 177, 227)
function Footer() {

    const {t} = useTranslation()

    return (
        <footer style={{backgroundColor: "rgb(80, 177, 227)"}}>
            <div>
                <div className="section-footer">
                    <Container>
                        <div className="custom-colored-text">
                            <img className="square-image-footer" src={'/images/Рамка футер.png'} alt="left"/>
                            <h1 className="custom-bold-40-contact footer_main_txt" > {t("contact")} </h1>
                            <div className="footer_text" >
                                <p>
                                    {t("OOO «Полимеркомплект»")} <br/>
                                    {t("tel")}  <br/>
                                    {t("el")} <br/>
                                    {t("ad")}
                                </p>
                            </div>
                        </div>

                    </Container>
                </div>
            </div>
        </footer>
    );
}
export default Footer;