import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import { Col, Modal, NavLink, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Typeahead } from "react-bootstrap-typeahead";
import React from "react";
import Cookie from "universal-cookie";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";


function NewNavBar({ opened, setOpened }) {
  const cookies = new Cookie();
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  // const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const selections = new Map();

  // const handleChange = (e) => {
  //     e.preventDefault();
  //     setPhone(e.target.value);
  //     setName(e.target.value);
  //     e.target.reset();
  // };
  const [productLoading, setProductLoading] = useState(false);
  const [product, setProduct] = useState([]);

  const fetchProducts = () => {
    return fetch("https://api.polymercomplect.com/api/getPopularProducts")
      .then(function (response) {
        setLoading(false);
        return response.json();
      })
      .then(function (data) {
        return setProducts(data);
      });
  };
  const fetchProduct = (product_id) => {
    setProductLoading(true);
    fetch("https://api.polymercomplect.com/api/getProductById?id=" + product_id)
      .then((response) => response.json())
      .then((data) => {
        setProductLoading(false);
        setProduct(data[0]);
        handleImages(data[0]);
        setModalShow(true);
      })
      .catch((err) => {
        setProductLoading(false);
        console.log(err.message);
      });
  };
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  function handleImages(product) {
    if (product != null && product.image != null) {
      let b = "https://admin.polymercomplect.com/image/" + product.image;
      // let ext = getExtension(product.image);
      // let sTh = product.image.replace('.' + ext, '-250x250.' + ext);
      let img1 = "https://admin.polymercomplect.com/image/" + product.image;

      let imgs = [
        {
          original: b,
          thumbnail: img1,
          originalHeight: 1000,
          originalWidth: 1000,
        },
      ];
      if (product.images != null) {
        product.images.split(";").map(function (item, _) {
          imgs.push({
            original: "https://admin.polymercomplect.com/image/" + item,
            thumbnail: "https://admin.polymercomplect.com/image/" + item,
            originalHeight: 1000,
            originalWidth: 1000,
          });
        });
      }
      console.log(product.images);
      setImages(imgs);
    }
  }

  function htmlDecode(input) {
    let e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  function createSession(id, callback) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.polymercomplect.com/api/newSession", requestOptions)
      .then((response) => response.text())
      .then(function (result) {
        const r = JSON.parse(result);
        cookies.set("session", r.session, { maxAge: 86000, path: "/" });
        callback(id);
      })
      .catch((error) => console.log("error", error));
  }
  function addRequest(id) {
    let session = cookies.get("session");
    if (session == null) {
      createSession(id, function (id) {
        addRequest(id);
      });
    } else {
      addToCarts(id, "/orders");
    }
  }
  function addToCard(id) {
    let session = cookies.get("session");
    if (session == null) {
      createSession(id, function (id) {
        addToCard(id);
      });
    } else {
      addToCarts(id, null);
    }
  }
  function addToCarts(id, toLink = null) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("session", cookies.get("session"));
    urlencoded.append("quantity", "1");
    urlencoded.append("product_id", id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.polymercomplect.com/api/addCard", requestOptions)
      .then((response) => response.text())
      .then(function (result) {
        if (result) {
          if (toLink != null) {
            window.location.href = toLink;
          } else {
            setModalShow(false);
            setProduct(null);
          }
        }
      })
      .catch((error) => console.log("error", error));
  }

  function handleOnChangeGroup(evt, category) {
    const target = evt.target;
    const checked = target.checked;
    // const name = target.name;

    let ids = [];
    if (category.subs !== undefined && category.subs != null) {
      for (const item of category.subs) {
        ids.push(item);
        selections.set(item, checked);
      }
    }
    fetchProducts(checked ? [0] : ids);
  }

  function sendEmail() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("to", "noreply@polymercomplect.com");
    urlencoded.append("subject", "Заказать звонок");
    urlencoded.append("text", "Имя: " + name + "\n Телефон номер: " + phone);
    urlencoded.append("html", "Имя: " + name + ";\n Телефон номер: " + phone);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://api.polymercomplect.com/api/sendEmail", requestOptions)
      .then((response) => {
        Swal.fire(
          "Совсем скоро мы с Вами свяжемся",
          "Благодарим за обращение",
          "success"
        );
        handleClose();
        console.log("sent");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Ошибка при отправке, попробуйте позже", "", "error");
      });
  }
  let mapCategories = new Map();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchData = () => {
    return fetch("https://api.polymercomplect.com/api/getAllCategories")
      .then((response) => response.json())
      .then(function (data) {
        mapCategories.clear();
        return setCategories(data);
      });
  };
  const fetchOptions = () => {
    return fetch("https://api.polymercomplect.com/api/query?q=")
      .then((response) => response.json())
      .then(function (data) {
        return setOptions(data);
      });
  };
  useEffect(() => {
    fetchOptions();
    fetchData();
  }, []);
  //ss

  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "ru" ? "en" : "ru");
  };

  const logoRu = "/images/polimeks_ru.png"
  const logoEn = "/images/polimeks_en.png"
  const phoneEn = "/images/phone_icon_en.png"
  const phoneRu = "/images/phone_icon_ru.png"
  

  return (
    <Navbar
      scrolling
      expand="lg"
      variant="dark"
      style={{ backgroundColor: "#50B1E3", position: "sticky", top: 0, zIndex: 99 }}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={i18n.language === "ru" ? logoRu : logoEn }
            height="60"
            className="d-inline-block"
            alt="Logo"
          />
        </Navbar.Brand>
        {/*<Navbar.Toggle aria-controls="navbarScroll" onClick={()=>changeToggle()} />*/}
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-lg-0 nav_items" navbarScroll>
            <Nav.Link
              style={{ marginTop: "10px", color: "white" }}
              className={
                window.location.pathname === "/"
                  ? "nav-bar-lg active_nav"
                  : "nav-bar-lg"
              }
              href="/"
            >
              {t("Главная")}
            </Nav.Link>
            <NavDropdown
              style={{
                marginTop: "10px",
                marginLeft: "-10px",
                marginRight: "20px",
                color: "white",
              }}
              title={
                <span
                  className={
                    window.location.pathname.indexOf("/products") === 0
                      ? "nav-bar-lg active_nav"
                      : "nav-bar-lg"
                  }
                >
                  {t("Продукция")}
                </span>
              }
              id="navbarScrollingDropdown"
            >
              {!opened &&
                categories.map(function (category, index) {
                  if (
                    category.parent_id !== 0 &&
                    !mapCategories.has(category.name)
                  ) {
                    const matchingCategories = categories.filter(
                      (cat) => cat.name === category.name
                    );
                    const categoryIds = matchingCategories.map(
                      (cat) => cat.category_id
                    );
                    mapCategories.set(category.name, categoryIds);

                    const categoryIdsString = categoryIds.join(",");

                    return (
                      <NavDropdown.Item
                        href={"/products?category_ids=" + categoryIdsString}
                        key={category.category_id}
                      >
                        {t(category.name)}
                      </NavDropdown.Item>
                    );
                  }
                })}

              {/*{opened===false && categories.map(function (category, index) {*/}
              {/*   if (*/}
              {/*     category.parent_id !== 0 &&*/}
              {/*     !mapCategories.has(category.name)*/}
              {/*   ) {*/}
              {/*     mapCategories.set(category.name, 0);*/}
              {/*     return (*/}
              {/*       <NavDropdown.Item*/}
              {/*         href={"/products?category_ids=" + category.category_id}*/}
              {/*         key={index}*/}
              {/*       >*/}
              {/*         {category.name}*/}
              {/*       </NavDropdown.Item>*/}
              {/*     );*/}
              {/*   }*/}
              {/* })}*/}
            </NavDropdown>
            <NavDropdown
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                color: "white",
              }}
              title={
                <span
                  className={
                    window.location.pathname === "/naftan" ||
                    window.location.pathname === "/kazan" ||
                    window.location.pathname === "/ufaorgsintez" ||
                    window.location.pathname === "/sibur" ||
                    window.location.pathname === "/neftehim" ||
                    window.location.pathname === "/nizhnekamsk"
                      ? "nav-bar-lg active_nav"
                      : "nav-bar-lg"
                  }
                >
                  {t("Заводы")}
                </span>
              }
              id="navbarScrollingDropdown"
              // eventKey="link-2"
            >
              <NavDropdown.Item href="/naftan">{t("ОАО «Нафтан»")}</NavDropdown.Item>
              <NavDropdown.Item href="/ufaorgsintez">
                {t("ПАО «Уфаоргсинтез»")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/sibur">{t("ПАО «СИБУР Холдинг»")}</NavDropdown.Item>
              <NavDropdown.Item href="/neftehim">{t("ТОО «Компания Нефтехим LTD»")}</NavDropdown.Item>
              <NavDropdown.Item href="/nizhnekamsk">
                {t("ПАО «Нижнекамскнефтехим»")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/kazan">{t("ПАО «Казаньоргсинтез»")}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="/contacts"
              eventKey="link-3"
              style={{ marginTop: "10px", marginLeft: "10px", color: "white" }}
              className={
                window.location.pathname === "/contacts"
                  ? "nav-bar-lg active_nav"
                  : "nav-bar-lg"
              }
            >
              {t("Контакты")}
            </Nav.Link>
          </Nav>
          <Form className="d-flex search search-nav"  >
            <Typeahead
              id="basic-example"
              onChange={(selected) => {
                try {
                  fetchProduct(selected[0].id);
                } catch (err) {
                  console.log(err);
                }
              }}
              options={options}
              placeholder={t("Поиск по товарам")}
              className="me-2 custom-input"
              labelKey={(option) => `${option.label} открыть товар`}
              style={{ width: "90%", marginTop: "10px" }}
            />
          </Form>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <a onClick={handleShow} style={{ cursor: "pointer" }}>
              <img
                className="nav_phone nav_img"
                src={i18n.language === "ru" ? phoneRu : phoneEn }
                alt={"phone_icon"}
              />
            </a>

            <a style={{ cursor: "pointer" }} href="/carts">
              <img
                className="nav_cart nav_img"
                src={"/images/корзина итог.png"}
                alt={"корзина"}
              />
            </a>
          </div>
            <button className="lang_switcher" onClick={changeLanguage}>
              {t("lang")}
            </button>

          {/*<Button id={'consultation-button'} variant="primary" className="custom-button call-button-lg" onClick={handleShow}>Заказать звонок</Button>*/}
          {/*<Button variant="primary" className="custom-button call-button" onClick={handleShow}><FontAwesomeIcon icon={faPhone}/></Button>*/}
          <Modal
            show={show}
            onHide={handleClose}
            animation={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h4 className="modal-h4">
                {t("manager")}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("name")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={"mobileBox custom-input"}
                    placeholder={t("name")}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("telefon")}</Form.Label>
                  <Form.Control
                    type="phone"
                    phone="phone"
                    required={true}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className={"mobileBox custom-input"}
                    placeholder="+7"
                  />
                </Form.Group>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    {t("close")}
                  </Button>
                  <Button
                    className="custom-button"
                    required={true}
                    variant="primary"
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    {t("zayaw")}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          {/*<Button variant="primary" className="button-cart" href="/carts"><img src="/images/cart.webp" height ="20" width="20" alt="cart"/></Button>*/}
        </Navbar.Collapse>
        <AiOutlineMenu
          onClick={() => setOpened(!opened)}
          className="burger_icon"
        />
        <div className={opened ? "burger_menu" : "burger_menu_passive"}>
          <a href="/" style={{ textDecoration: "none" }}>
            <p
              className={
                window.location.pathname === "/"
                  ? "nav-bar-lg active_nav"
                  : "nav-bar-lg"
              }
            >
              {t("Главная")}
            </p>
          </a>
          <NavDropdown
            style={{
              marginTop: "-10px",
              marginBottom: "10px",
              color: "white",
            }}
            title={
              <span
                className={
                  window.location.pathname.indexOf("/products") === 0
                    ? "nav-bar-lg active_nav"
                    : "nav-bar-lg"
                }
              >
                {t("Продукция")}
              </span>
            }
            id="navbarScrollingDropdown"
          >
            {opened === true &&
              categories.map(function (category, index) {
                if (
                  category.parent_id !== 0 &&
                  !mapCategories.has(category.name)
                ) {
                  mapCategories.set(category.name, 0);
                  return (
                    <NavDropdown.Item
                      href={"/products?category_ids=" + category.category_id}
                      key={index}
                    >
                      {t(category.name)}
                    </NavDropdown.Item>
                  );
                }
              })}
          </NavDropdown>
          <NavDropdown
            style={{
              marginTop: "0px",
              marginRight: "-25px",
              color: "white",
            }}
            title={
              <span
                className={
                  window.location.pathname === "/naftan" ||
                  window.location.pathname === "/ufaorgsintez" ||
                  window.location.pathname === "/sibur" ||
                  window.location.pathname === "/neftehim" ||
                  window.location.pathname === "/nizhnekamsk"
                    ? "nav-bar-lg active_nav"
                    : "nav-bar-lg"
                }
              >
                {t("Заводы")}
              </span>
            }
            id="navbarScrollingDropdown"
            // eventKey="link-2"
          >
            <NavDropdown.Item href="/naftan">{t("ОАО Нафтан")}</NavDropdown.Item>
            <NavDropdown.Item href="/ufaorgsintez">
            {t("ПАО Уфаоргсинтез")}
            </NavDropdown.Item>
            <NavDropdown.Item href="/sibur">{t("ПАО «СИБУР Холдинг»")}</NavDropdown.Item>
            <NavDropdown.Item href="/neftehim">{t("ТОО «Компания Нефтехим LTD»")}</NavDropdown.Item>
            <NavDropdown.Item href="/nizhnekamsk">
            {t("ПАО «Нижнекамскнефтехим»")}
            </NavDropdown.Item>
          </NavDropdown>
          <a style={{ textDecoration: "none" }} href="/contacts">
            <p
              eventKey="link-3"
              style={{ marginTop: "10px", color: "white" }}
              className={
                window.location.pathname === "/contacts"
                  ? "nav-bar-lg active_nav"
                  : "nav-bar-lg"
              }
            >
              {t("Контакты")}
            </p>
          </a>
          <Form className="burger_input">
            <Typeahead
              id="basic-example"
              onChange={(selected) => {
                try {
                  fetchProduct(selected[0].id);
                } catch (err) {
                  console.log(err);
                }
              }}
              options={options}
              placeholder={t("Поиск по товарам")}
              className="me-2 custom-input"
              labelKey={(option) => `${option.label} открыть товар`}
              style={{ width: "100%", marginTop: "10px" }}
            />
          </Form>
          <div className="burger_phone_cart">
            <img
              src={"/images/burger/burger_phone.png"}
              onClick={handleShow}
              style={{ marginTop: "10px", width: "15%" }}
              alt={"корзина"}
            />
            <a style={{ cursor: "pointer" }} href="/carts">
              <img
                href="/carts"
                src={"/images/burger/burger_cart.png"}
                style={{ marginTop: "10px", width: "290%" }}
                alt={"корзина"}
              />
            </a>
            <button className="lang_switcher mobile" onClick={changeLanguage}>
              {t("lang")}
            </button>
          </div>
        </div>
      </Container>
      <Modal
        show={modalShow}
        product1={product}
        onHide={function () {
          setProduct(null);
          return setModalShow(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("productCart")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <ImageGallery
                items={images}
                showNav={false}
                showPlayButton={false}
                lazyLoad={true}
                showIndex={true}
              />
            </Col>
            <Col>
              <h4>{product !== null ? product.name : ""}</h4>
              <br />
              {/*<p>{product.model}</p>*/}
              <div className="button-cart-buy">
                <Button
                  onClick={() => addToCard(product.product_id)}
                  variant="primary custom-button"
                  style={{
                    width: "75%",
                    borderRadius: "0px",
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  {t("korzina")}
                </Button>
                <Button
                  onClick={() => addRequest(product.product_id)}
                  variant="primary custom-button"
                  style={{
                    width: "75%",
                    borderRadius: "0px",
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  {t("zakaz")}
                </Button>
              </div>
              <p style={{ marginTop: 20, fontWeight: "bold" }}>
                {t("marka")}{" "}
                <span style={{ fontWeight: "normal" }}>
                  {product !== null ? product.model : ""}{" "}
                </span>
              </p>
              <p style={{ marginTop: 20, fontWeight: "bold" }}>
                {t("proiz")}{" "}
                <span
                  style={{ fontWeight: "normal" }}
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(
                      product !== null ? product.manufacturer : ""
                    ),
                  }}
                />
              </p>
              <div>
                <span style={{ fontWeight: "bold" }}>{t("opis")}</span>
                <div
                  style={{ display: "grid", fontSize: "13px" }}
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(
                      product !== null ? product.description : ""
                    ),
                  }}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="custom-button" onClick={() => setModalShow(false)}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={productLoading}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span> Загрузка...</span>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}
export default NewNavBar;
