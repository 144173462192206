import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import {Col, Modal, Placeholder, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Make sure to import the i18n instance
function ProductsSection() {
    const cookies = new Cookies();
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [productLoading, setProductLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const selections = new Map();
    
    const languageCode = () => {
        if (i18n.language === 'ru') {
          return 'ru-ru';
        } else {
          return 'en-gb';
        }
      };

      const fetchProducts = async () => {
        try {
          const response = await fetch(`https://api.polymercomplect.com/api/getPopularProducts?language=${languageCode()}`);
          const data = await response.json();
          setProducts(data);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
    }
    // const fetchProducts = () => {
    //     // return fetch(`https://api.polymercomplect.com/api/getPopularProducts?language=${language}`)
    //     return fetch(`http://localhost:8081/api/getPopularProducts?language=${languageCode}`)
    //         .then(function (response) {
    //             setLoading(false);
    //             return response.json();
    //         })
    //         .then(function (data) {
    //             return setProducts(data);
    //         });
    // }
    // const fetchProduct = (product_id) => {
    //     setProductLoading(true);
    //     fetch('https://api.polymercomplect.com/api/getProductById?id='+product_id)
    //     // fetch('http://localhost:8083/api/getProductById?id='+product_id)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setProductLoading(false);
    //             setProduct(data[0]);
    //             handleImages(data[0]);
    //             setModalShow(true);
    //         })
    //         .catch((err) => {
    //             setProductLoading(false);
    //             console.log(err.message);
    //         });
    // }

    const fetchProduct = (product_id) => {
        setProductLoading(true);      
        // fetch(`https://api.polymercomplect.com/api/getProductById?id=${product_id}&language=${languageCode}`)
        fetch(`https://api.polymercomplect.com/api/getProductById?id=${product_id}&language=${languageCode()}`)
          .then((response) => response.json())
          .then((data) => {
            setProductLoading(false);
            setProduct(data[0]);
            handleImages(data[0]);
            setModalShow(true);
          })
          .catch((err) => {
            setProductLoading(false);
            console.log(err.message);
          });
      }

    function createSession(id, callback){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://api.polymercomplect.com/api/newSession", requestOptions)
            .then(response => response.text())
            .then(function (result) {
                const r = JSON.parse(result)
                cookies.set('session',r.session, {maxAge:86000, path: '/'});
                callback(id);
            })
            .catch(error => console.log('error', error));
    }
    function addRequest(id){
        let session = cookies.get('session');
        if(session==null){
            createSession(id, function (id) {
                addRequest(id)
            });
        }else {
            addToCarts(id, '/orders');
        }
    }
    function addToCard(id) {
        let session = cookies.get('session');
        if(session==null){
            createSession(id, function (id) {
                addToCard(id)
            });
        }else {
            addToCarts(id, null);
        }
    }
    function addToCarts(id, toLink=null) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("session", cookies.get('session'));
        urlencoded.append("quantity", "1");
        urlencoded.append("product_id", id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://api.polymercomplect.com/api/addCard", requestOptions)
            .then(response => response.text())
            .then(function (result) {
                if(result){
                    if(toLink!=null){
                        window.location.href=toLink;
                    }else {
                        setModalShow(false)
                        setProduct(null)
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
    function htmlDecode(input){
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    useEffect(() => {
        fetchProducts();
    },[i18n.language])
    function handleOnChange(evt) {
        const target = evt.target;
        const checked = target.checked;
        // const name = target.name;
        const id = target.id;

        selections.set(id, checked)
        fetchProducts(checked?[0]:[id]);
    }
    function handleImages(product){
        if(product != null && product.image!=null){
            let b = "https://admin.polymercomplect.com/image/" + (product.image);
            // let ext = getExtension(product.image);
            // let sTh = product.image.replace('.' + ext, '-250x250.' + ext);
            // let img1 = 'https://admin.farmplst.com/image/cache/' + sTh;

            let imgs = [
                {
                    original: b,
                    thumbnail: b,
                    originalHeight: 1000,
                    originalWidth: 1000,
                },
            ]
            if (product.images != null) {
                product.images.split(';').map((function (item, _) {
                    imgs.push({
                        original: "https://admin.polymercomplect.com/image/" + item,
                        thumbnail: "https://admin.polymercomplect.com/image/" + item,
                        originalHeight: 1000,
                        originalWidth: 1000,
                    })
                }))
            }
            console.log(product.images);
            setImages(imgs);
        }
    }
    function handleOnChangeGroup(evt, category) {
        const target = evt.target;
        const checked = target.checked;
        // const name = target.name;
        let ids = [];
        if(category.subs!==undefined && category.subs!=null){
            for (const item of category.subs){
                ids.push(item)
                selections.set(item, checked)
            }
        }
        fetchProducts(checked?[0]:ids);
    }
    function getExtension(filename) {
        return filename.split(".").pop();
    }
    const {t} = useTranslation()
    if(loading){
        return (
            <div className={'section2'}>
            <Container id={'products-section'}>
                <div className="custom-colored-text">
                    <div>
                        <h1 className="custom-bold-40 products-head-text">{t("popular")}</h1>
                        <div className="square-image-sec1">
                            <img src={'/images/square.png'} alt="left"/>
                        </div>
                    </div>
                </div>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col md="auto">
                        <Card className="card-hov">
                            <Card.Img variant="top" src="/images/placeholder.webp" alt="card_image4" />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                <Placeholder.Button variant="primary" className={'custom-button'} xs={6} />
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }else if(products.length>0){
        // eslint-disable-next-line array-callback-return
        function getExtension(filename) {
            // eslint-disable-next-line array-callback-return
            return filename.split(".").pop();
        }
        return (
            <div className={'section2'}>
            <Container id={'products-section'}>
                <div className="custom-colored-text">
                    <div>
                        <h1 className="custom-bold-40" >{t("popular")}</h1>
                        <div className="square-image-sec1">
                            <img src={'/images/square.png'} alt="left"/>
                        </div>
                    </div>
                </div>
                <Row className="justify-content-md-center">
                    {
                        // eslint-disable-next-line array-callback-return
                        products.map(function (product, index) {
                            let ext = getExtension(product.image);
                            ext = product.image.replace('.'+ext,'-250x250.'+ext);
                            return (
                                <Col md="auto" key={index}>
                                    <Card className="card-hov">
                                        <Card.Img onClick={function () {
                                            fetchProduct(product.product_id);
                                        }} className={'img-loading'} variant="top" src={'http://admin.polymercomplect.com/image/cache/'+ext}
                                                  onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null; // prevents looping
                                                      currentTarget.src="/images/placeholder.webp";
                                                  }} alt={product.name}/>
                                        <Card.Body onClick={function () {
                                            fetchProduct(product.product_id);
                                        }}>
                                            <h6 className={'card-text-name'} style={{textAlign: "left", color: '#343434', fontWeight: "bold"}}>{t(product.name)}</h6>
                                            <p style={{textAlign: "left", fontWeight: "bold"}}>{t("marka")} <span style={{fontWeight: "normal"}} dangerouslySetInnerHTML={{__html: htmlDecode(t(product.model))}}></span> </p>
                                            <p style={{textAlign: "left" , fontWeight: "bold"}}>{t("proiz")} <span style={{fontWeight: "normal"}} dangerouslySetInnerHTML={{__html: htmlDecode(t(product.manufacturer))}}></span> </p>
                                        </Card.Body>
                                        <Card.Footer style={{backgroundColor:'transparent', borderTop:'none', padding:'0 0 16px 16px'}}>
                                            <Button onClick={()=>addRequest(product.product_id)} variant="primary custom-button" style={{width:'75%', borderRadius:'0px'}}>
                                                {t("zayaw")}</Button>
                                        </Card.Footer>
                                    </Card>
                                    <br/>
                                </Col>
                            );
                        })
                    }
                </Row>
                
                {/*<Row className="justify-content-md-center">*/}
                {/*    {*/}
                {/*        // eslint-disable-next-line array-callback-return*/}
                {/*        products.map(function (product, index) {*/}
                {/*            let ext = getExtension(product.image);*/}
                {/*            ext = product.image.replace('.'+ext,'-250x250.'+ext);*/}
                {/*            return (*/}
                {/*                <Col md="auto" key={index}>*/}
                {/*                    <Card className="card-hov">*/}
                {/*                        <Card.Img onClick={function () {*/}
                {/*                            fetchProduct(product.product_id);*/}
                {/*                        }} className={'img-loading'} variant="top" src={'http://admin.farmplst.com/image/cache/'+ext}*/}
                {/*                                  onError={({ currentTarget }) => {*/}
                {/*                                      currentTarget.onerror = null; // prevents looping*/}
                {/*                                      currentTarget.src="/images/placeholder.webp";*/}
                {/*                                  }} alt={product.name}/>*/}
                {/*                        <Card.Body onClick={function () {*/}
                {/*                            fetchProduct(product.product_id);*/}
                {/*                        }}>*/}
                {/*                            <h6 className={'card-text-name'} style={{textAlign: "left", color: '#343434', fontWeight: "bold"}}>{product.name}</h6>*/}
                {/*                            <p style={{textAlign: "left", fontWeight: "bold"}}>Марка: <span style={{fontWeight: "normal"}}>{product.model}</span> </p>*/}
                {/*                            <p style={{textAlign: "left" , fontWeight: "bold"}}>Производитель: <span style={{fontWeight: "normal"}} dangerouslySetInnerHTML={{__html: htmlDecode(product.manufacturer)}}></span> </p>*/}
                {/*                        </Card.Body>*/}
                {/*                        <Card.Footer style={{backgroundColor:'transparent', borderTop:'none', padding:'0 0 16px 16px'}}>*/}
                {/*                            <Button onClick={()=>addRequest(product.product_id)} variant="primary custom-button" style={{width:'75%', borderRadius:'0px'}}>*/}
                {/*                                Оставить заявку</Button>*/}
                {/*                        </Card.Footer>*/}
                {/*                    </Card>*/}
                {/*                    <br/>*/}
                {/*                </Col>*/}
                {/*            );*/}
                {/*        })*/}
                {/*    }*/}
                {/*</Row>*/}
                <Modal
                    show={modalShow}
                    product1 = {product}
                    onHide={function () {
                        setProduct(null);
                        return setModalShow(false);
                    }}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Карточка продукта
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <ImageGallery
                                    items={images}
                                    showNav={false}
                                    showPlayButton={false}
                                    lazyLoad={true}
                                    showIndex={true}
                                />
                            </Col>
                            <Col>
                                <h4>{product!==null?product.name:''}</h4>
                                <br/>
                                {/*<p>{product.model}</p>*/}
                                <div className="button-cart-buy">
                                    <Button onClick={()=>addToCard(product.product_id)} variant="primary custom-button" style={{width:'75%', borderRadius:'0px', marginBottom: 20,marginLeft: 20}}>Добавить в корзину</Button>
                                    <Button onClick={()=>addRequest(product.product_id)} variant="primary custom-button" style={{width:'75%', borderRadius:'0px', marginBottom: 20,marginLeft: 20}}>Заказать в один клик</Button>
                                </div>
                                <p style={{marginTop: 20, fontWeight: "bold"}}>Марка: <span style={{fontWeight: "normal"}} dangerouslySetInnerHTML={{__html: htmlDecode(t(product!==null?product.model:''))}}/>
                                </p>
                                <p style={{marginTop: 20, fontWeight: "bold"}}>Производитель: <span style={{fontWeight: "normal"}} dangerouslySetInnerHTML={{__html: htmlDecode(t(product!==null?product.manufacturer:''))}}/></p>
                                <div><span style={{fontWeight: "bold"}}>Описание:</span>
                                    <div style={{display:'grid', fontSize:'13px'}} dangerouslySetInnerHTML={{__html: htmlDecode(product!==null?product.description:'')}}/>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="custom-button" onClick={()=>setModalShow(false)}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={productLoading}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <span> Загрузка...</span>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Container>
            </div>
        )
    }
}

export default ProductsSection;