import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

function Help() {
  const location = useLocation();
  //   console.log(location.pathname);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  function sendEmail() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("to", "noreply@polymercomplect.com");
    urlencoded.append("subject", "Помощь в выборе продукта и подходящего способа оплаты");
    urlencoded.append(
        "text",
        "Имя: " +
        name +
        ";\n" +
        "Почта: " +
        email +
        ";\n" +
        "Телефон номер: " +
        phone
    );
    urlencoded.append(
        "html",
        'Имя: ' +
        name +
        ';\n Почта: '+ email + +
        ';\n Телефон номер: '+ phone
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.polymercomplect.com/api/sendEmail", requestOptions)
        .then((response) => {
          Swal.fire(
              "Совсем скоро мы с Вами свяжемся",
              "Благодарим за обращение",
              "success"
          );
          setName("");
          setEmail("");
          setPhone("");
          console.log("sent");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Ошибка при отправке, попробуйте позже", "", "error");
        });
  }

  const {t} = useTranslation()

  return (
    <div className="section4" id="help">
      <Container>
        <div className="custom-colored-text ">
          <div className="help_text">
            <h1 className="custom-bold-40 " >
                {t("help")}
            </h1>
            <div className="square-image-sec1">
              <img src={"/images/square.png"} alt="left" />
            </div>
          </div>
        </div>
        <div className="help">
          <div className="help_form">
            <h2>{t("zayaw")}</h2>
            <input className="help_input" placeholder={t("name")}
                   type="text"
                   name="name"
                   required={true}
                   value={name}
                   onChange={(e) => setName(e.target.value)}
            />
            <input className="help_input" placeholder={t("email")}
                   type="email"
                   email="email"
                   required={true}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className="help_input" placeholder={t("telefon")}
                   type="phone"
                   phone="phone"
                   required={true}
                   value={phone}
                   onChange={(e) => setPhone(e.target.value)}
            />
            <button className="help_send_btn" onClick={() => {
              sendEmail();
            }}> {t("send")}</button>
          </div>
        </div>
      </Container>
      <div className="home_page_images help_div">
        <img
          className="custom-section1-img help_img"
          src={"/images/call.webp"}
          alt="call"
        />
      </div>
    </div>
  );
}

export default Help;
