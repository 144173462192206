import React from "react";
import MainSlider from "./components/slider-section";
import AboutSection from "./components/about-section";
import ProductsSection from "./components/products-section";
import OtstrochksSection from "./components/otstrochks-section";
import InfoSection from "./components/info-section";
import ImportSection from "./components/import-section";
import Help from "./components/help";
import MapSection from "./components/map-section";

export const HomePage = () => {
  return (
    <div>
      <MainSlider />
      <AboutSection />
      <ProductsSection />
      <ImportSection />
      <Help />
      <OtstrochksSection />
      <InfoSection />
      <MapSection />
    </div>
  );
};
