import React from "react";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

function AboutSection() {

    const {t} = useTranslation()

  return (
    <div className={"section1"} id="about">
      <Container>
        <div className="custom-colored-text">
          <div>
            <h1
              className="custom-bold-40"
              style={{ paddingLeft: 60, marginTop: "80px" }}
            >
              {t("О нас")}
            </h1>
            <div
              className="square-image-sec1"
              style={{ zIndex: 0, marginTop: "-90px" }}
            >
              <img src={"/images/square.png"} alt="left" />
            </div>
          </div>
          <div className="about_us_text" style={{fontSize: '16px'}}>
            <p>
              {t("polyText")}<br />
              {t("polyTextMini")}<br />
              <span className="custom-bold">
                -
                <a
                  href="/sibur"
                  style={{ color: "#008DA9", textDecoration: "none" }}
                >
                  {" "}
                  {t("ПАО «СИБУР Холдинг»")}
                </a>
                  <a>,</a>
                  <a>{t("include")}</a>
                {" "}
                <a
                    style={{ color: "#008DA9", textDecoration: "none" }}
                    href="/nizhnekamsk"
                >
                  {" "}
                  {t("ПАО «Нижнекамскнефтехим»")}
                </a>
                  <a> </a>
                <a>{t("and")}</a>
                 <a
                     style={{ color: "#008DA9", textDecoration: "none" }}
                     href="/kazan"
                 >
                  {" "}
                  {t("ПАО «Казаньоргсинтез»")}
                </a>
  <br/>
                - <a
                  style={{ color: "#008DA9", textDecoration: "none" }}
                  href="/ufaorgsintez"
                >
                  {t("ПАО «НК «РОСНЕФТЬ»")},
                </a>
                <br/>
                -
                <a
                  style={{ color: "#008DA9", textDecoration: "none" }}
                  href="/neftehim"
                >
                  {" "}
                  {t("ТОО «Компания Нефтехим LTD»")}
                </a>
<br/>
                -
                <a
                  style={{ color: "#008DA9", textDecoration: "none" }}
                  href="/naftan"
                >
                  {" "}
                  {t("ОАО «Нафтан»")}.
                </a>
                <br/>
              </span>
              {t("postText")}{" "}
              <span className="custom-bold">{t("tons")}</span> <a> </a>
              <span className="custom-bold">{t("clientText")}</span> {t("clientTextEnd")}
              <br />
             {t("descText")}{" "}
              <span className="custom-bold">{t("descTextEnd")}</span>
              <br />
              {t("sotText")}
            </p>
          </div>

          {/* <ul>
                        <li><FontAwesomeIcon icon={faStop} color={'#008DA9'} style={{marginRight:'10px'}}/>Нафтан</li>
                        <li><FontAwesomeIcon icon={faStop} color={'#008DA9'} style={{marginRight:'5px'}} />Уфаоргсинтез</li>
                        <li><FontAwesomeIcon icon={faStop} color={'#008DA9'} style={{marginRight:'5px'}} />Сибур</li>
                        <li><FontAwesomeIcon icon={faStop} color={'#008DA9'} style={{marginRight:'5px'}} />Нефтехим</li>
                        <li><FontAwesomeIcon icon={faStop} color={'#008DA9'} style={{marginRight:'5px'}} />Нижнекамскнефтехим</li>
                    </ul> */}
        </div>
      </Container>
      <div className="home_page_images about">
        <img
          className="custom-section1-img about_img"
          src={"/images/newabout.webp"}
          alt="newabout"
        />
      </div>
    </div>
  );
}

export default AboutSection;
