import React from "react";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import {useTranslation} from "react-i18next"

function ImportSection() {


    const {t} = useTranslation()

  return (
    <div className="section3">
      <Container>
        <div className="custom-colored-text">
          <div className="import_header">
            <h1 className="custom-bold-40 import_t" style={{ paddingLeft: 60 }}>
              {t("import")}
            </h1>
            <div className="square-image-sec1">
              <img src={"/images/square.png"} alt="left" />
            </div>
          </div>
          <div className="import_text">
            <p>
                {t("importText")}
             
            </p>
          </div>
          <div className="import_txt">
            <p>
              <span className="custom-bold">
                {t("importDescText")} <br />
                {t("importDescText2")}
              </span>
              <br/>
            </p>
          </div>
        </div>
      </Container>
      <div className="home_page_images import">
        <img
          className="custom-section1-img import_section_img"
          src={"/images/import.webp"}
          alt="newabout"
        />
      </div>
    </div>
  );
}

export default ImportSection;
