import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
// import white_area from '/front/public/images/white_area.png'
import { useTranslation } from 'react-i18next';

function MapSection() {
  const [phone, setPhone] = useState("");
  const handleChange = (e) => {
    setPhone(e.target.value);
  };
  function sendEmail() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("to", "noreply@polymercomplect.com");
    urlencoded.append("subject", "Получить консультацию");
    urlencoded.append("text", "Телефон номер: " + phone);
    urlencoded.append("html", "Телефон номер: " + phone);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://api.polymercomplect.com/api/sendEmail", requestOptions)
      .then((response) => {
        Swal.fire("Совсем скоро мы с Вами свяжемся", "sddsfsdfsd", "success");
        // e.target.reset();
        console.log("sent");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Ошибка при отправке, попробуйте позже", "", "error");
      });
  }

  const {t} = useTranslation()

  return (
    <div className="section7" id="map-section">
      <Container>
        <div className="custom-colored-text">
          <div className="map_header_text">
            <h1 className="custom-bold-40 map_text">{t("map")}</h1>
            <div className="square-image-sec2">
              <img src={"/images/square.png"} alt="left" />
            </div>
            <img
              src={"/images/white_area.png"}
              className="white_area_map"
              alt="white_area"
            />
          </div>
        </div>
      </Container>
      <video className="custom-section2-vid" autoPlay style={{}} loop muted playsInline={true}>
        <source src="/images/compo.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

export default MapSection;
