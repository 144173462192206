import * as React from "react";
import Container from "react-bootstrap/Container";
import {Col, FormLabel, ListGroup, Modal, Placeholder, Row, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {useEffect, useState} from "react";
import Cookie from 'universal-cookie';
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { useTranslation } from 'react-i18next';

// const [loading, setLoading] = useState(true);

export function NaftanPage() {
  const cookies = new Cookie();

  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const selections = new Map();
  const [show, setShow] = useState(false);
  const image = [
    '/images/polietilen_vysokogo_davleniya.webp',
  ];

  function checkId(id) {
    const queryParams = new URLSearchParams(window.location.search)
    const ids = queryParams.get("category_ids")

    if(ids!==null && ids!==""){
      const ids1 = ids.split(',');
      for (const x of ids1) {
        if(x==id){
          return true;
        }
      }
    }
    return false;
  }

  function handleImages(product){
    if(product != null && product.image!=null && product.image!=undefined){
      let b = "https://admin.polymercomplect.com/image/" + (product.image);
      // let ext = getExtension(product.image);
      // let sTh = product.image.replace('.' + ext, '-250x250.' + ext);
      // let img1 = 'https://admin.farmplst.com/image/cache/' + sTh;

      let imgs = [
        {
          original: b,
          thumbnail: b,
          originalHeight: 1000,
          originalWidth: 1000,
        },
      ]
      if (product.images != null) {
        product.images.split(';').map((function (item, _) {
          imgs.push({
            original: "https://admin.polymercomplect.com/image/" + item,
            thumbnail: "https://admin.polymercomplect.com/image/" + item,
            originalHeight: 1000,
            originalWidth: 1000,
          })
        }))
      }
      console.log('imgs[0]: ', imgs[0]);
      console.log(product.images);
      setImages(imgs);
    }
  }


  function createSession(id, callback){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.polymercomplect.com/api/newSession", requestOptions)
        .then(response => response.text())
        .then(function (result) {
          const r = JSON.parse(result)
          cookies.set('session',r.session, {maxAge:86000, path: '/'});
          callback(id);
        })
        .catch(error => console.log('error', error));
  }
  function addRequest(id){
    let session = cookies.get('session');
    if(session==null){
      createSession(id, function (id) {
        addRequest(id)
      });
    }else {
      // addToCarts(id, '/orders');
    }
  }
  let mapCategories = new Map();
  const fetchData = () => {
    return fetch("https://api.polymercomplect.com/api/getAllCategories")
        .then((response) => response.json())
        .then(function (data) {
          mapCategories.clear();
          return setCategories(data);
        });
  };


  function htmlDecode(input){
    let e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const ids = queryParams.get("category_ids")

    if(ids!==null && ids!==""){
      const ids1 = ids.split(',');
      for (const x of ids1) {
        if(x!==''){
          selections.set(x, true);
        }
      }
    }
  },[])

  function handleOnChange(evt) {
    const target = evt.target;
    const checked = target.checked;
    // const name = target.name;
    const id = target.id;

    selections.set(id, checked)
  }
  const [options, setOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);


  const fetchPassport = () => {
    // Fetch passport data from the API
    fetch('https://api.polymercomplect.com/api/getPassportProducts')
        .then((response) => response.json())
        .then((data) => {
          // Filter options based on sort_order and option_values
          const filteredOptions = data.filter(
              (option) => option.sort_order === 0 && option.option_values.length > 0
          );
          setOptions(filteredOptions);
        })
        .catch((error) => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
    fetchPassport();
  }, []);
  const handleCardClick = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  const {t} = useTranslation()

  return (
    <div>
      <div className="factory-div">
        <img className="d-block  image-size" src="/images/partners/naftanF.webp" alt="naftan"/>
        <div className="factory-mini-div">
          <img className="factory-mini-img naftan_logo" src="/images/partners/naftan.webp" alt="naftanF"/>
        </div>
      </div>
      <div className="custom-factory-div">
        <p>
            {t("n1")}
          <br />
          {t("n2")}
          <br />
          {t("n3")}
          <br />
          {t("n4")}
          <br />
          {t("n5")}
          <br />
        </p>
      </div>

      <div className={"section1"}>
        <div>
          <h1 className="custom-factory-div">{t("passport")}</h1>
          <Container id="products-section">
            <div className="custom-colored-text"></div>
            <Row className="justify-content-md-start m-lg-5">
                {options.map((option) =>
                    option.option_values.map((optionValue, index) => (
              <Col md="auto">
                        <Card
                            key={`${option.option_id}_${index}`}
                            className="card-hov"
                            onClick={() => handleCardClick("https://admin.polymercomplect.com/image/" + optionValue.image)}
                        >
                          <Card.Img
                              variant="top"
                              src={"https://admin.polymercomplect.com/image/" + optionValue.image}
                              alt={`card_image_${index}`}
                          />
                        </Card>
                <br />
              </Col>
                    ))
                )}
            </Row>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen={true}
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  {options.map((option) => option.option_name)}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  {selectedImage && (
                      <img src={selectedImage} alt="modal_image" />
                  )}
                </Row>
              </Modal.Body>
            </Modal>
          </Container>
          {/*<h1 className="custom-factory-div">Паспорта качества</h1>*/}
          {/*<Container id="products-section">*/}
          {/*  <div className="custom-colored-text"></div>*/}
          {/*  <Row className="justify-content-md-start m-lg-5">*/}
          {/*    {options.map((option) =>*/}
          {/*        option.option_values.map((optionValue, index) => (*/}
          {/*    <Col md="auto">*/}
          {/*          <Card*/}
          {/*              key={`${option.option_id}_${index}`}*/}
          {/*              className="card-hov"*/}
          {/*              onClick={() => setShow(true)}*/}
          {/*          >*/}
          {/*            <Card.Img*/}
          {/*                variant="top"*/}
          {/*                src={"https://admin.polymercomplect.com/image/" + optionValue.image}*/}
          {/*                alt={`card_image_${index}`}*/}
          {/*            />*/}
          {/*          </Card>*/}
          {/*      <br />*/}
          {/*    </Col>*/}
          {/*        ))*/}
          {/*        )}*/}
          {/*  </Row>*/}
          {/*  <Modal*/}
          {/*      show={show}*/}
          {/*      onHide={() => setShow(false)}*/}
          {/*      dialogClassName="modal-90w"*/}
          {/*      aria-labelledby="example-custom-modal-styling-title"*/}
          {/*      fullscreen={true}*/}
          {/*  >*/}
          {/*    <Modal.Header closeButton>*/}
          {/*      <Modal.Title id="example-custom-modal-styling-title">*/}
          {/*        {options.map((option) => option.option_name)}*/}
          {/*      </Modal.Title>*/}
          {/*    </Modal.Header>*/}
          {/*    <Modal.Body>*/}
          {/*      <Row>*/}
          {/*        {options.map((option) =>*/}
          {/*            option.option_values.map((optionValue, index) => (*/}
          {/*                <img*/}
          {/*                    key={`${option.option_id}_${index}`}*/}
          {/*                    src={"https://admin.polymercomplect.com/image/" + optionValue.image}*/}
          {/*                    alt={`modal_image_${index}`}*/}
          {/*                />*/}
          {/*            ))*/}
          {/*        )}*/}
          {/*      </Row>*/}
          {/*    </Modal.Body>*/}
          {/*  </Modal>*/}
          {/*</Container>*/}
        </div>
      </div>
      <div className={"section1"}>
        <div>
          <h1 className="custom-factory-div">{t("types")}</h1>
        </div>
        <Container id={"products-section"}>
          {categories
              .filter((category) => category.parent_id === 0 && category.sort_order === 0)
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((parentCategory) => {
                const childrenCategories = categories.filter(
                    (category) => category.parent_id === parentCategory.category_id
                );
                const parentCategoryIds = [parentCategory.category_id];
                const childCategoryIds = childrenCategories.map((category) => category.category_id);
                const categoryIds = parentCategoryIds.concat(childCategoryIds);
                mapCategories.set(parentCategory.name, categoryIds);

                const categoryIdsString = categoryIds.join(',');

                return (
                    <Container key={parentCategory.category_id}>
                          <Row className="justify-content-md-start m-lg-5">
                      {childrenCategories.map((childCategory, index) => (
                            <Col md="auto">
                              <Card
                                  key={childCategory.category_id}
                                  className="card-hov text-center"
                                  href={`/products?category_ids=${childCategory.category_id}`}
                              >
                                <Card.Img
                                    variant="top"
                                    src={image[index]}
                                    alt={image[index]}
                                />
                                <Card.Body>
                                  <h6 className={'card-text-name text-center'} style={{textAlign: "left", color: '#343434', fontWeight: "bold"}}> {t(childCategory.name)}</h6>
                                     </Card.Body>
                                <Card.Footer style={{backgroundColor:'transparent', borderTop:'none', padding:'0 16px 16px 16px'}}>
                                  <Button href={`/products?category_ids=${childCategory.category_id}`} variant="primary custom-button" style={{width:'75%', borderRadius:'0px'}}>
                                    {t("Перейти")}</Button>
                                </Card.Footer>
                              </Card>
                              <br />
                            </Col>
                      ))}
                          </Row>
                          {/*// <ul key={childCategory.category_id}>*/}
                          {/*//   <a href={`/products?category_ids=${childCategory.category_id}`}>*/}
                          {/*//     {childCategory.name}*/}
                          {/*//   </a>*/}
                          {/*// </ul>*/}
                    </Container>
                );
              })}


          {/*//With parent name*/}
          {/*{categories*/}
          {/*    .filter((category) => category.parent_id === 0 && category.sort_order === 0)*/}
          {/*    .sort((a, b) => a.sort_order - b.sort_order)*/}
          {/*    .map((parentCategory) => {*/}
          {/*      const childrenCategories = categories.filter(*/}
          {/*          (category) => category.parent_id === parentCategory.category_id*/}
          {/*      );*/}
          {/*      const parentCategoryIds = [parentCategory.category_id];*/}
          {/*      const childCategoryIds = childrenCategories.map((category) => category.category_id);*/}
          {/*      const categoryIds = parentCategoryIds.concat(childCategoryIds);*/}
          {/*      mapCategories.set(parentCategory.name, categoryIds);*/}

          {/*      const categoryIdsString = categoryIds.join(',');*/}

          {/*      return (*/}
          {/*          <ul key={parentCategory.category_id}>*/}
          {/*            <a href={`/products?category_ids=${categoryIdsString}`}>*/}
          {/*              {parentCategory.name}*/}
          {/*            </a>*/}
          {/*            {childrenCategories.map((childCategory) => (*/}
          {/*                <ul key={childCategory.category_id}>*/}
          {/*                  <a href={`/products?category_ids=${childCategory.category_id}`}>*/}
          {/*                    {childCategory.name}*/}
          {/*                  </a>*/}
          {/*                </ul>*/}
          {/*            ))}*/}
          {/*          </ul>*/}
          {/*      );*/}
          {/*    })}*/}

        </Container>
      </div>
    </div>
  );
}
