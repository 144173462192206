import * as React from 'react';
import Container from "react-bootstrap/Container";
import {Col, Modal, Placeholder, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from 'react-i18next';


export function NizhnekamskPage() {
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const images = [
        '/images/polistirol.webp',
        '/images/polipropilen.webp'
    ];
    useEffect(() => {
        fetchPassport();
        fetchData();
    }, []);

    const fetchPassport = () => {
        // Fetch passport data from the API
        fetch('https://api.polymercomplect.com/api/getPassportProducts')
            .then((response) => response.json())
            .then((data) => {
                // Filter options based on sort_order and option_values
                const filteredOptions = data.filter(
                    (option) => option.sort_order === 4 && option.option_values.length > 0
                );
                setOptions(filteredOptions);
            })
            .catch((error) => console.error('Error:', error));
    };
    const handleCardClick = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    let mapCategories = new Map();
    const fetchData = () => {
        return fetch("https://api.polymercomplect.com/api/getAllCategories")
            .then((response) => response.json())
            .then(function (data) {
                mapCategories.clear();
                return setCategories(data);
            });
    };

    const {t} = useTranslation()

    return (
            <div>
                <div className="factory-div">
                <img className="d-block  image-size" src="/images/partners/nizhnekamskF.webp" alt="nizhnekamskF"/>
                    <div className="factory-mini-div">
                        <img className="factory-mini-img naftan_logo" src="/images/partners/nizhnekamsk.webp" alt="nizhnekamsk"/>
                    </div>
                </div>
                    <div className="custom-factory-div">
                        <p>
                            {t("nes1")}<br/>
                            {t("nes2")}<br/>
                            {t("nes3")}<br/>
                            {t("nes4")} <br/>
                            {t("nes5")}<br/>
                            {t("nes6")}<br/>
                            {t("nes7")}<br/>
                            {t("nes8")}<br/>

                        </p>
                    </div>

                <div className={'section1'}>
                    <div>
                        <h1 className="custom-factory-div">{t("passport")}</h1>
                        <Container id="products-section">
                            <div className="custom-colored-text"></div>
                            <Row className="justify-content-md-start m-lg-5">
                                {options.map((option) =>
                                    option.option_values.map((optionValue, index) => (
                                        <Col md="auto">
                                            <Card
                                                key={`${option.option_id}_${index}`}
                                                className="card-hov"
                                                onClick={() => handleCardClick("https://admin.polymercomplect.com/image/" + optionValue.image)}
                                            >
                                                <Card.Img
                                                    variant="top"
                                                    src={"https://admin.polymercomplect.com/image/" + optionValue.image}
                                                    alt={`card_image_${index}`}
                                                />
                                            </Card>
                                            <br />
                                        </Col>
                                    ))
                                )}
                            </Row>
                            <Modal
                                show={show}
                                onHide={() => setShow(false)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                fullscreen={true}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        {options.map((option) => option.option_name)}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        {selectedImage && (
                                            <img src={selectedImage} alt="modal_image" />
                                        )}
                                    </Row>
                                </Modal.Body>
                            </Modal>
                        </Container>
                    </div>
                </div>
                <div className={'section1'}>
                    <div>
                        <h1 className="custom-factory-div">{t("types")}</h1>
                    </div>
                    <Container id={'products-section'}>
                        {categories
                            .filter((category) => category.parent_id === 0 && category.sort_order === 4)
                            .sort((a, b) => a.sort_order - b.sort_order)
                            .map((parentCategory) => {
                                const childrenCategories = categories.filter(
                                    (category) => category.parent_id === parentCategory.category_id
                                );
                                const parentCategoryIds = [parentCategory.category_id];
                                const childCategoryIds = childrenCategories.map((category) => category.category_id);
                                const categoryIds = parentCategoryIds.concat(childCategoryIds);
                                mapCategories.set(parentCategory.name, categoryIds);

                                const categoryIdsString = categoryIds.join(',');

                                return (
                                    <Container id={'products-section'}>
                                        {categories
                                            .filter((category) => category.parent_id === 0 && category.sort_order === 4)
                                            .sort((a, b) => a.sort_order - b.sort_order)
                                            .map((parentCategory) => {
                                                const childrenCategories = categories.filter(
                                                    (category) => category.parent_id === parentCategory.category_id
                                                );
                                                const parentCategoryIds = [parentCategory.category_id];
                                                const childCategoryIds = childrenCategories.map((category) => category.category_id);
                                                const categoryIds = parentCategoryIds.concat(childCategoryIds);
                                                mapCategories.set(parentCategory.name, categoryIds);

                                                const categoryIdsString = categoryIds.join(',');

                                                return (
                                                    <Container key={parentCategory.category_id}>
                                                        <Row className="justify-content-md-start m-lg-5">
                                                            {childrenCategories.map((childCategory, index) => (
                                                                <Col md="auto">
                                                                    <Card
                                                                        key={childCategory.category_id}
                                                                        className="card-hov text-center"
                                                                        href={`/products?category_ids=${childCategory.category_id}`}
                                                                    >
                                                                        <Card.Img
                                                                            variant="top"
                                                                            src={images[index]}
                                                                            alt={images[index]}
                                                                        />
                                                                        <Card.Body>
                                                                            <h6 className={'card-text-name text-center'} style={{textAlign: "left", color: '#343434', fontWeight: "bold"}}> {t(childCategory.name)}</h6>
                                                                        </Card.Body>
                                                                        <Card.Footer style={{backgroundColor:'transparent', borderTop:'none', padding:'0 16px 16px 16px'}}>
                                                                            <Button href={`/products?category_ids=${childCategory.category_id}`} variant="primary custom-button" style={{width:'75%', borderRadius:'0px'}}>
                                                                                {t("Перейти")}</Button>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                    <br />
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Container>
                                                );
                                            })}
                                    </Container>
                                );
                            })}
                    </Container>
                </div>
            </div>

    );
};