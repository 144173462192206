import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import React from "react";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { useTranslation } from 'react-i18next';


function InfoSection() {
  
    const {t} = useTranslation()
  
    return (
    <div>
      <div className="section6">
        <Container>
          <div className="custom-colored-text">
            <div className="info_header_text">
              <h1 className="custom-bold-40" >
                {t("info")}
              </h1>
              <div className="square-image-sec1">
                <img src={"/images/square.png"} alt="left" />
              </div>
            </div>
            <div className="info_text" style={{fontSize: '16px'}}>
              <p>
                {t("infoText")} <br/>
                {t("infoText2")}
              </p>
            
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default InfoSection;